import AutoComplete from 'js-autocomplete';

let autocompleteObject;

function autocompleteInit() {
    autocompleteObject = new AutoComplete({
        selector: '#search',
        cache: false,
        minChars: 1,
        delay: 20,
        source(term, response) {
            const request = new XMLHttpRequest();
            const params = { query: term };
            const encodedParams = Object.keys(params).map((param) => `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`);
            let autocompleteUrl = document.getElementById('search').getAttribute('data-autocomplete');
            autocompleteUrl = (autocompleteUrl.indexOf('?') === -1) ? `${autocompleteUrl}?` : `${autocompleteUrl}&`;
            request.open('GET', autocompleteUrl + encodedParams.join('&'), true);
            request.onload = function autocompleteOnload() {
                if (request.status >= 200 && request.status < 400) {
                    response(JSON.parse(request.responseText));
                } else {
                    // We reached our target server, but it returned an error
                }
            };
            request.onerror = function autocompleteOnerror() {
                // There was a connection error of some sort
            };
            request.send();
        },
        renderItem(item, originalSearch) {
            const search = originalSearch.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            const re = new RegExp(`(.*?\\s|^)(${search})(.*)`, 'gi');
            return `<div class="autocomplete-suggestion" data-val="${item}">${item.replace(re, '$1$2<em>$3</em>')}</div>`;
        },
        onSelect(e, term, item) {
            document.querySelector('#search_form').submit();
        },
    });
}

autocompleteInit();

function setSelection(chbx) {
    const checkbox = chbx;
    const allCheckboxes = checkbox.closest('form').querySelectorAll(checkbox.getAttribute('data-group'));
    const countChecked = [].reduce.call(allCheckboxes, (accumulator, currentCheckbox) => {
        if (currentCheckbox === checkbox) {
            return accumulator;
        }
        return accumulator + (currentCheckbox.checked ? 1 : 0);
    }, 0);

    if (countChecked === allCheckboxes.length) {
        checkbox.indeterminate = false;
        checkbox.checked = true;
    } else if (countChecked === 0) {
        checkbox.indeterminate = false;
        checkbox.checked = false;
    } else {
        checkbox.indeterminate = true;
    }
}

document.querySelector('.show-filter-form').addEventListener('click', (e) => {
    e.preventDefault();
    const filterElement = document.querySelector('#filter_form');
    if (filterElement.classList.contains('visible')) {
        document.querySelector('#filter_form').classList.remove('visible');
    } else {
        document.querySelector('#filter_form').classList.add('visible');
    }
});

document.querySelector('#filter_form').addEventListener('click', (e) => {
    if (e.target.classList.contains('filter-headline')) {
        e.target.parentElement.classList.toggle('hidden');
    }
});

document.querySelector('#filter_form').addEventListener('change', (e) => {
    if (!e.target.hasAttribute('data-group')) {
        document.querySelector('#filter_form').submit();
    }
});
document.querySelector('#type_form').addEventListener('change', (e) => {
    if (!e.target.hasAttribute('data-group')) {
        document.querySelector('#type_form').submit();
    }
});
if (document.querySelector('#page_count_form')) {
    document.querySelector('#page_count_form').addEventListener('change', (e) => {
        if (!e.target.hasAttribute('data-group')) {
            document.querySelector('#page_count_form').submit();
        }
    });
}
document.querySelector('#results-container').addEventListener('change', (e) => {
    if (e.target.hasAttribute('data-group')) {
        const checkboxSelectAll = e.target;

        const allCheckboxes = checkboxSelectAll.closest('form').querySelectorAll(checkboxSelectAll.getAttribute('data-group'));
        [].forEach.call(allCheckboxes, (singleCheck) => {
            const checkbox = singleCheck;
            checkbox.checked = checkboxSelectAll.checked;
        });

        e.preventDefault();

        setSelection(e.target);
        e.target.closest('form').submit();
    }
});
